import Vue from 'vue/dist/vue.js'
window._ = require('lodash/core');
window._.find({}, false);

import Swiper, {Pagination, Navigation, Autoplay} from 'swiper';
import 'swiper/swiper-bundle.css';

import VueExpandableImage from 'vue-expandable-image';
// Vue.component('expandable-image', VueExpandableImage);
Vue.use(VueExpandableImage);

Swiper.use([Pagination, Navigation, Autoplay]);



/* Vue Material */

import { 
    MdButton,
    MdDialog,
    MdContent,
    MdProgress,
    MdCheckbox,
    MdRadio,
    MdDrawer,
    MdEmptyState,
    MdField,
    MdList,
    MdSnackbar,
    MdDatepicker,
    MdMenu,
    MdAutocomplete,
    // MdSelect,
    // MdOption,
    // MdFile,
} from 'vue-material/dist/components'

import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

// Remove Vue Material's auto added theme class from HTML

document.querySelector('html').className = '';

//

import './main.scss'

Vue.use(MdButton)
Vue.use(MdDialog)
Vue.use(MdContent)
Vue.use(MdProgress)
Vue.use(MdCheckbox)
Vue.use(MdRadio)
Vue.use(MdDrawer)
Vue.use(MdEmptyState)
Vue.use(MdField)
Vue.use(MdList)
Vue.use(MdSnackbar)
Vue.use(MdDatepicker)
Vue.use(MdMenu)
Vue.use(MdAutocomplete)
// Vue.use(MdSelect)
// Vue.use(MdOption)
// Vue.use(MdFile)

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/* Tecto UI */

import './tecto-ui/styles/base/global.scss'
import './tecto-ui/styles/base/pwa.scss'
import './tecto-ui/styles/themes/new.scss'

import './tecto-ui/styles/components/modal.scss'
import './tecto-ui/styles/components/toast.scss'
import './tecto-ui/styles/components/icon.scss'
import './tecto-ui/styles/components/button.scss'
import './tecto-ui/styles/components/input/_all.scss'
import './tecto-ui/styles/components/menu.scss'
import './tecto-ui/styles/layout/button-group.scss'
import './tecto-ui/styles/layout/grid.scss'

import './tecto-ui/styles/layout/bar.scss';
import './tecto-ui/styles/helpers/noscrollbars.scss';
import './tecto-ui/styles/helpers/vcen.scss';

import './styles/tecto-ui-custom.scss';

// Prime Icons

import 'primeicons/primeicons.css';

import Catalog from './components/Catalog_02.vue';
Vue.component('v-catalog', Catalog);

import RequestQuoteForm from './components/RequestQuoteForm.vue';
Vue.component('request-quote-form', RequestQuoteForm);

import ChsAthleteForm from './components/ChsAthleteForm.vue';
Vue.component('chs-athlete-form', ChsAthleteForm);

import ContactForm from './components/ContactForm.vue';
Vue.component('contact-form', ContactForm);

import CatalogProviderLinks from './components/CatalogProviderLinks.vue';
Vue.component('catalog-provider-links', CatalogProviderLinks);

import GalleryLauncher from './components/GalleryLauncher.vue';
Vue.component('gallery-launcher', GalleryLauncher);

Vue.config.productionTip = false

new Vue({
    el: '#app',

    data () {
        return {
            showScrollArrowDown: true,
            bgYOffset: 0,
            isNavLargeFixed: false,
            activeNavPreview: '',
            navMenuTimer: null,
            showTeamDialog: false,
            showRequestQuote: false,
            showDesignLab: false,
            teamMember: {},
            teamMemberLoaded: false,
            showShare: false,
            showVideoDialog: false,
            showPhotoDialog: false,
            showFileInstructions: false,
            videoId: null,
            // View Photo
            photoTitle: '',
            photoUrl: '',
            // showAppDrawer: false,
            showNavDrawer: false,
            showSocialDrawer: false,
            swipers: {},
            showReadIntro: false,
            // overlayHelpTimer: {},
            overlayHelpShow: false,
            wasHelpOverlayShown: false,
        }
    },

    mounted () 
    {
        this.handleResizeNav();

        // Scroll listener for changing nav states and parallax hero background effect
        
        let 
        dirPrev = '',
        dir = '',
        posPrev = window.pageYOffset || document.body.scrollTop,
        posCur = posPrev,
        distance = 0,
        debounce = null;

        distance = distance+1; // No unused vars linter hack?!

        window.addEventListener('scroll', () => {

            if ( debounce ) {
                window.cancelAnimationFrame(debounce);
            }

            debounce = window.requestAnimationFrame(() => {
                posCur = window.pageYOffset || document.body.scrollTop;
                dir = posPrev <= posCur ? 'up' : 'down';
                
                if ( dir === dirPrev )
                {
                    distance += Math.abs(posCur - posPrev);
                }
                else
                {
                    distance = 0;
                }

                posPrev = posCur;
                dirPrev = dir;

                // clearTimeout(scrollTimer);

                // scrollTimer = setTimeout(() => {
                //     distance = 0;
                // }, 100);

                if ( distance >= 600 )
                {
                    // if ( dir === this.scrollDirHide )
                    // {
                    //     this.hideBar();
                    // }
                    // else
                    // {
                    //     this.showBar();
                    // }

                    // Show help overlay once if user is scrolling back up the page a certain amount (as if they might be lost)

                    if ( dir === 'down' )
                    {
                        if ( !this.wasHelpOverlayShown ) {
                            // this.overlayHelpShow = true;
                            // this.navPreview('help');
                            // this.wasHelpOverlayShown = true;
                        }
                    }
                    
                    distance = 0;
                }

                // Set nav bar to fixed positioning after page is scrolled a bit
                
                if ( posCur >= 45 ) {
                    this.isNavLargeFixed = true;
                    document.documentElement.style.setProperty('--nav-menu-top', "75px");
                    // this.activeNavPreview = '';
                } else if ( window.screen.availHeight >= 445 ) {
                    this.isNavLargeFixed = false;
                    document.documentElement.style.setProperty('--nav-menu-top', "165px");
                }

                this.showScrollArrowDown = posCur < 60;

                // Background parallax effect. 
                // Only activate if screen size is large enough since it is jittery on mobile

                // if ( posCur < (window.screen.availHeight * .8) + 45 && window.screen.availHeight > 600 && window.screen.availWidth > 600 ) // Factor in bottom shout-outs banner
                // {
                    if ( this.$refs.heroBg ) {
                        // this.bgYOffset = (posCur * .5);
                        // this.$refs.heroBg.style.backgroundPosition = 'center ' + (posCur * .5) + 'px';
                        this.$refs.heroBg.style.transform = 'translateY('+(posCur * .5)+'px)';
                        // this.heroMessageOffsetY = posCur * .6;
                        this.$refs.heroMessage.style.marginTop = (posCur * .6) + 'px';
                    }
                // }
            }); // debounce
        })

        // Set nav to fixed if there is not enough vertical space to display its tall form
        // Hide Nav/Social drawers on resize

        // let rT;

        window.addEventListener('resize', () => {

            this.showSocialDrawer = this.showNavDrawer = false;

            // clearTimeout(rT);

            // rT = setTimeout( () => {
                if ( window.screen.availHeight < 600 ) {
                    this.isNavLargeFixed = true;
                    document.documentElement.style.setProperty('--nav-menu-top', "75px");
                }
            // }, 10);
        });

        // On page load: Set nav to fixed when there is not enough vertical height

        this.handleResizeNav();

        // Start timer for opening call to action for home page only
        // Timer will start on each pageload after a set time period. Make it long to prevent annoyance.

        // this.overlayHelpTimer = setTimeout(() => {
        //     this.overlayHelpShow = true;
        // }, 20000);

        // Scroll for hiding mobile UI elements for a more progressive web app feel.
        window.scrollTo(0, 1);
        let T = this;

        // new Swiper('#slides-main', {
        //     direction: 'horizontal',
        //     loop: true,
        //     disableOnInteraction: true,

        //     pagination: {
        //         el: '.swiper-pagination',
        //         clickable: true,
        //     },

        //     autoplay: {
        //         delay: 6000,
        //     },

        //     slidesPerView: 1,
        //     autoResize: true,

        //     observer: true, 
        //     observeParents: true,
        // });

        new Swiper('#hero-bg', {
            direction: 'horizontal',
            loop: true,
            speed: 1000,
            disableOnInteraction: false,

            autoplay: {
                delay: 6000,
            },

            slidesPerView: 1,
            autoResize: true,

            observer: true, 
            observeParents: true,
        });

        // new Swiper('#brand-logos-bar', {
        //     direction: 'horizontal',
        //     loop: true,
        //     speed: 1500,
        //     disableOnInteraction: true,

        //     autoplay: {
        //         delay: 0,
        //     },

        //     slidesPerView: 'auto',
        //     autoResize: true,
        //     spaceBetween: 30,
        //     freeMode: true,

        //     observer: true, 
        //     observeParents: true,
        // });

        new Swiper('#slides-testimonials', {
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            slidesPerView: 'auto',
            spaceBetween: 30,
            disableOnInteraction: true,
            autoplay: {
                delay: 20000,
            },
        })

        new Swiper('#slides-videos', {
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            slidesPerView: 'auto',
            spaceBetween: 30,
            // preventClicks: false,
            // preventClicksPropagation: false,
            on: {
                click: (swiper) => {
                    let videoId = swiper.clickedSlide.getAttribute('data-video-id');
                    T.videoDialog(videoId);
                    T.$forceUpdate();
                }
            }
        })

        new Swiper('#featured-items', {
            /*pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },*/
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            slidesPerView: 3,
            spaceBetween: 30,
            // preventClicks: false,
            // preventClicksPropagation: false,
            on: {
                // click: (swiper) => {
                //     let videoId = swiper.clickedSlide.getAttribute('data-video-id');
                //     T.videoDialog(videoId);
                //     T.$forceUpdate();
                // }
            },
            breakpoints: {
                1300: {
                    slidesPerView: 3
                },
                700: {
                    slidesPerView: 2
                },
                0: {
                    slidesPerView: 1
                }
            },
            observer: true, 
            observeParents: true,
        })

        /*new Swiper('#gallery .swiper-container', {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            slidesPerView: 1,
            spaceBetween: 30,
            observer: true, 
            observeParents: true,   
        })*/
    },

    components: {
        // VueExpandableImage,
    },

    methods: 
    {
        navMenuTimerStop()
        {
            clearTimeout(this.navMenuTimer);
        },

        navMenuTimerStart()
        {
            this.navMenuTimer = setTimeout( () => {
                this.activeNavPreview = '';
            }, 300);
        },

        bgStyles()
        {
            return {'background-position': 'center ' + this.bgYOffset + 'px'};
        },

        openWindow(url)
        {
            window.open(url);
        },
        navPreview(str)
        {
            if ( this.activeNavPreview === str )
            {
                this.activeNavPreview = ''
            }
            else
            {
                this.activeNavPreview = '';

                this.$nextTick( () => {
                    this.activeNavPreview = str;
                })
            }
        },
        navPreviewHover(/*str*/)
        {
            // Disabled for now due to mobile issue - when open, hides after clicking another parent menu item instead of switching.
            // if ( this.activeNavPreview !== '' )
            // {
            //     this.activeNavPreview = str;
            // }
        },

        livechatProxy () {
            this.navPreview('');
            this.$refs.livechatProxy.children[0].click();
        },

        teamDialog (id)
        {
            this.teamMemberLoaded = false

            // Show dialog and wait for content

            this.showTeamDialog = true

            // Load Details

            window.axios.get('/api/team-member/bio/'+id)
            .then( (response) => {
                this.teamMember = response.data
                this.teamMemberLoaded = true
            } )

        },
        requestQuote ()
        {
            this.showRequestQuote = !this.showRequestQuote
        },

        videoAction(source, video_id)
        {
            switch ( source ) {
                case 'instagram':
                    console.log('open', video_id)
                    window.open(video_id);
                    break;
                default:
                    this.videoId = video_id;
                    this.showVideoDialog = true;
            }
        },

        viewPhoto(title, url)
        {
            this.photoTitle = title;
            this.photoUrl = url;
            this.showPhotoDialog = true;
        },

        handleResizeNav()
        {
            if ( window.screen.availHeight < 600 ) {
                this.isNavLargeFixed = true;
                document.documentElement.style.setProperty('--nav-menu-top', "75px");
            }
        }
    }
})
