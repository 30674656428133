<template>
    <div class="catalog">

        <md-dialog 
        :md-active.sync="showCatalogFilters">

            <md-dialog-content>

                <keep-alive>
                <v-catalog-filters 
                @filter="filter($event)" 
                :section="section" 
                :search="search"
                ref="v-catalog-filters-mobile"></v-catalog-filters>
                </keep-alive>

            </md-dialog-content>

            <md-dialog-actions>
                <div class="bar align:right">
                    <a class="button style:ch-medium-blue" @click="showCatalogFilters = false" style="color: #fff">
                        <!-- <i class="icon"><i class="pi pi-close"></i></i> -->
                        <span>Done</span>
                    </a>
                </div>
            </md-dialog-actions>

        </md-dialog>

        <div class="filters">

            <v-catalog-filters 
            @filter="filter($event)" 
            :section="section" 
            :search="search"
            ref="v-catalog-filters"></v-catalog-filters>

        </div>

        <md-progress-bar 
        md-mode="indeterminate"
        v-if="!catalogsLoaded"></md-progress-bar>

        <div cen class="filter-button">
            <a class="button style:ch-medium-blue" @click="showCatalogFilters = true">
                <i class="icon"><i class="pi pi-search"></i></i>
                <!-- <span>Search</span> -->
            </a>
        </div>

        <div class="search-detail" v-if="filters.search">
            <p>Results for: "{{ filters.search }}"</p>
        </div>


        <div class="catalog-items" v-if="hasResults && catalogsLoaded">


            <!-- <div class="selections" v-if="catalogsLoaded && hasFilters && (filters.sections.length || filters.categories.length)">
                Showing results for: 
                <b v-for="section in filters.sections" :key="section.id">{{ section.name }}</b>
                <b v-for="category in filters.categories" :key="category.id">{{ category.name }}</b>
            </div> -->

            <a 
            class="catalog-item"
            v-for="catalog in catalogsFiltered" :key="catalog.key"
            :href="catalog.url" target="_blank">

                <!-- Catalog Thumb Image -->

                <div v-if="catalog.imageThumbComputed" class="img" :style="{backgroundImage: 'url('+catalog.imageThumbComputed+')'}">
                    <!-- <img :src="catalog.imageThumbComputed" /> -->
                </div>

                <!-- Store Image (When item is a Store) -->

                <div v-if="catalog.imageComputed" class="img" :style="{backgroundImage: 'url('+catalog.imageComputed+')'}">
                    <!-- <img :src="catalog.imageComputed" /> -->
                </div>

                <h5>{{ catalog.name }}</h5>
            </a>

            
        </div>

        <div v-if="!hasResults && catalogsLoaded" style="padding-top: 5em;">
            <!-- <md-empty-state
            md-rounded
            md-label="No Results"
            md-description="Try another search...">
            </md-empty-state> -->
            <h2>No results found for "{{ filters.search }}"</h2>
            <p>Browse our custom catalog provider sites below:</p>
            <catalog-provider-links></catalog-provider-links>
        </div>

    </div>
</template>

<script>

import CatalogFilters from './CatalogFilters.vue';

export default {
    name: 'v-catalog',
    props: {
        section: String,
        search: String,
    },
    data: function() {
        return {
            catalogs: [],
            stores: [],
            catalogsCombined: [],
            catalogsFiltered: [],
            catalogsLoaded: false,
            filters: [],
            hasFilters: false,
            hasResults: false,
            showCatalogFilters: false,
        }
    },
    components: {
        'v-catalog-filters': CatalogFilters,
    },
    methods: {
        getCatalogs () 
        {
            window.axios.get('/api/catalogs')
            .catch(() => {

            })
            .then((res) => {
                this.catalogs = res.data
                this.getStores()
            })
        },
        getStores () 
        {
            window.axios.get('/api/catalogs/stores')
            .catch(() => {

            })
            .then((res) => {
                this.stores = res.data

                // Combine Catalogs and Stores for a more centralized organization

                this.catalogsCombined = this.catalogs.concat(this.stores);
                this.catalogsFiltered = this.catalogsCombined;

                this.filter();
            })
        },
        filter(filters)
        {
            filters = filters || this.filters;
            this.filters = filters;
            // this.hasFilters = true;
            window.scrollTo(0, 0);

            let filtered = [];

            filtered = window._.filter(this.catalogsCombined, (o) => {
                return  !this.filters.search 
                            || 
                        o.name.toLowerCase().includes( this.filters.search.toLowerCase() ) 
                            || 
                        window._.some( o.tags, (ob) => { return ob.name.toLowerCase().includes( this.filters.search.toLowerCase() ) } )
                            ||
                        window._.some( o.brands, (ob) => { return ob.name.toLowerCase().includes( this.filters.search.toLowerCase() ) } )
            })

            if ( !this.filters.search && this.filters.section && this.filters.section.id )
            {
                filtered = window._.filter(filtered, (o) => 
                {
                    if ( o.section_id === this.filters.section.id )
                    {
                        return true
                    }

                    return false
                })
            }

            if ( !this.filters.search && this.filters.category && this.filters.category.id )
            {
                filtered = window._.filter(filtered, (o) => 
                {
                    if ( o.catalog_category_id === this.filters.category.id )
                    {
                        return true
                    }

                    return false
                })
            }

            this.hasResults = filtered.length > 0;

            // Output the filtered array while removing duplicates

            this.catalogsFiltered = filtered;

            this.catalogsLoaded = true;
        },
    },
    created() {
        this.getCatalogs()
    },
}
</script>

<style lang="scss">

    .catalog {
        display: grid;
        grid-gap: 2em;
        grid-template-columns: 300px 1fr;
        align-content: start;

        .filters {
            position: sticky;
            top: var(--nav-large-height-fixed);
        }

        .search-detail {
            position: fixed;
            left: 0;
            top: var(--nav-large-height-fixed);
            height: 40px;
            background: var(--ch-medium-blue);
            z-index: 749;
            
            p {
                color: #fff;
                margin: 0;
            }
            
            display: grid;
            place-items: center;
        }
    }

    .selections {
        margin-bottom: 2em;
        
        b {
            display: inline-block;
            padding: .5em;
        }
    }

    .filter-button {
        display: none;

        position: fixed;
        top: 40vh;
        right: 20px;
        width: auto;

        animation: pulse 2s ease 0s infinite normal forwards;

        .button {
            width: 3em;
            height: 3em;
            border-radius: 10em;
            box-shadow: 0 0 0 2px #fff;
            box-sizing: border-box;

            .icon {
                font-size: 1.2em;
            }
        }
    }

    .catalog-items {
        --max-width: 250px;
        display: grid;
        grid-gap: 1em;
        grid-template-columns: repeat(auto-fit, minmax(0, var(--max-width)));
        justify-content: center;

        .catalog-item {
            background: #fff;
            padding: 1em;
            aspect-ratio: .7;
            border: 1px solid #D5D5D5;

            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr auto;
            grid-gap: 2em;
            place-items: center;

            .img {
                height: 100%;
                display: grid;
                place-content: center;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;

                // img {
                //     max-height: 100%;
                //     align-self: center;
                // }
            }
            

            h5 {
                width: 100%;
                font-size: 1.2em;
                font-weight: 400;
                text-align: center;
                margin: 0;
            }
        }
    }

    @media ( max-width: 1300px ) {
        .catalog {
            font-size: .9em;
            grid-template-columns: 1fr;
        }

        .filters {
            display: none;
        }

        .filter-button {
            display: block;
        }
    }

    @media ( max-width: 650px ) {
        .catalog-items {
            --max-width: 200px;
        }
    }


    @keyframes pulse {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.3);
        }

        100% {
            transform: scale(1);
        }
    }

</style>
