<template>
    <div id="request-quote-form">

        <form @submit.prevent="validateUser" enctype="multipart/form-data" ref="form">

            <!-- GPA Ack -->

            <div class="field" :class="getValidationClass('is_gpa_acknowledged')">
                <p>
                    <b>All College Hype Athletes must maintain a grade point average (GPA) of 3.0 or higher.</b>
                </p>
                <md-checkbox v-model="form.details.is_gpa_acknowledged" :true-value="1" :false-value="0" class="md-primary">Acknowledge</md-checkbox>
            </div>

            <!-- Volunteer Ack -->

            <div class="field" :class="getValidationClass('is_community_service_acknowledged')">
                <p>
                    <b>All College Hype Athletes must volunteer at least 16 hours of their time to a non-profit organization of their choice.</b>
                </p>
                <md-checkbox v-model="form.details.is_community_service_acknowledged" :true-value="1" :false-value="0" class="md-primary">Acknowledge</md-checkbox>
            </div>

            <!-- Core Values Ack -->

            <div class="field" :class="getValidationClass('is_core_values_acknowledged')">
                <p>
                    <b>All College Hype Athletes must follow our core values.</b><br />
                </p>
                <div style="display: block; padding: 1em;">
                    <img src="images/chs-athlete-core-values.svg?v=02" />
                </div>
                <md-checkbox v-model="form.details.is_core_values_acknowledged" :true-value="1" :false-value="0" class="md-primary">Acknowledge</md-checkbox>
            </div>

            <!-- Agreement -->

            <div class="field" :class="getValidationClass('is_agreement_accepted')">
                <p>
                    <b>In order to be considered a College Hype Athlete you will need to add “College Hype Athlete” to your social media bios. In the following section you’ll be asked to upload photos of yourself in the uniform of the school you are attending or the school you will attend. In the event that you do not take these steps you cannot be considered. Please submit an essay, up to 200 words, explaining why we should consider you for the College Hype Athlete program. This question is required. *</b>
                </p>
                <p>
                    <b>Requirements:</b>
                    <ul>
                        <li>Prior to becoming a College Hype Athlete you must have 1,000+ followers on one or more of the following social media channels: Instagram, Twitter, TikTok.</li>
                        <li>You will receive new College Hype branded merch once a quarter. You are required to wear this right away.</li>
                        <li>College Hype Athletes must positively promote College Hype on and off of the sports field.</li>
                        <li>College Hype Athletes must post one video message promoting the charity of your choice should be added to social media.</li>
                    </ul>
                </p>
                <p><small>As a member of the College Hype Athlete program, it is important to us that you follow all relevant laws and policies relating to the use of your name, image, and likeness. By participating in the program, you are agreeing that you will comply with those laws and policies and, similarly, are confirming that you will not use your name, image, or likeness to endorse any prohibited conduct, such as gambling, sports betting, drugs, or alcohol. Lastly, when you send us a picture of yourself, you are giving College Hype the right to use and post it on any College Hype platform or channel and confirming that you have the rights required to allow us to use it (so that our use does not violate anyone’s rights).</small></p>
                <md-checkbox v-model="form.details.is_agreement_accepted" :true-value="1" :false-value="0" class="md-primary">I accept the terms &amp; conditions &amp; requirements.</md-checkbox>
            </div>

            <!-- First Name -->

            <div class="field" :class="getValidationClass('first_name')">
                <md-field :class="getValidationClass('first_name')">
                    <label for="first_name">First Name *</label>
                    <md-input name="first-name" id="first_name" autocomplete="given-name" v-model="form.first_name" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.first_name.required">First Name is required</span>
                    <span class="md-error" v-else-if="!$v.form.first_name.maxLength">Max length: 64 chars</span>
                </md-field>
            </div>

            <!-- Last Name -->

            <div class="field" :class="getValidationClass('last_name')">
                <md-field :class="getValidationClass('last_name')">
                    <label for="last_name">Last Name *</label>
                    <md-input name="last-name" id="last_name" autocomplete="given-name" v-model="form.last_name" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.last_name.required">Last Name is required</span>
                    <span class="md-error" v-else-if="!$v.form.last_name.maxLength">Max length: 64 chars</span>
                </md-field>
            </div>

            <!-- Email -->

            <div class="field" :class="getValidationClass('email')">
                <md-field :class="getValidationClass('email')">
                    <label for="email">Email Address *</label>
                    <md-input name="email" id="email" v-model="form.email" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.email.required">Email Address is required</span>
                    <span class="md-error" v-if="!$v.form.email.email">Must be a valid email address</span>
                    <span class="md-error" v-else-if="!$v.form.email.maxLength">Max length: 64 chars</span>
                </md-field>
            </div>

            <!-- Essay -->

            <div class="field" :class="getValidationClass('essay')">
                <md-field :class="getValidationClass('essay')">
                    <label for="essay">Essay (1000 characters or less) *</label>
                    <md-textarea v-model="form.details.essay" maxlength="1000" :md-autogrow="true"></md-textarea>
                    <span class="md-error" v-if="!$v.form.details.essay.required">A short essay is required</span>
                    <span class="md-error" v-else-if="!$v.form.details.essay.maxLength">Must be 1000 characters or less</span>
                </md-field>
            </div>
            

            <!-- DETAILS -->

            <!-- Birth Date -->

            <div class="field" :class="getValidationClass('birth_date')">
                <label head for="date_needed">Birth Date *</label>
                <md-datepicker v-model="form.details.birth_date" :md-override-native="true" :md-model-type="String">
                    <label>Select date</label>
                </md-datepicker>
                <span class="md-error" v-if="!$v.form.details.birth_date.required">Please choose a date</span>
            </div>

            <!-- Email School -->

            <div class="field" :class="getValidationClass('email_school')">
                <md-field :class="getValidationClass('email_school')">
                    <label for="email_school">School Email Address *</label>
                    <md-input name="email_school" id="email_school" v-model="form.details.email_school" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.details.email_school.required">School Email Address is required</span>
                    <span class="md-error" v-if="!$v.form.details.email_school.email">Must be a valid email address</span>
                </md-field>
            </div>

            <!-- Phone -->

            <div class="field" :class="getValidationClass('phone')">
                <md-field :class="getValidationClass('phone')">
                    <label for="phone">Phone Number *</label>
                    <md-input name="phone" id="phone" v-model="form.details.phone" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.details.phone.required">Phone Number is required</span>
                    <span class="md-error" v-else-if="!$v.form.details.phone.maxLength">Too many characters</span>
                </md-field>
            </div>

            <!-- Address -->

            <div class="field" :class="getValidationClass('address')">
                <md-field :class="getValidationClass('address')">
                    <label for="address">Address *</label>
                    <md-input name="address" id="address" v-model="form.details.address" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.details.address.required">Phone Number is required</span>
                    <span class="md-error" v-else-if="!$v.form.details.address.maxLength">Too many characters</span>
                </md-field>
            </div>
            
            <!-- City -->

            <div class="field" :class="getValidationClass('city')">
                <md-field :class="getValidationClass('city')">
                    <label for="city">City *</label>
                    <md-input name="city" id="city" v-model="form.details.city" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.details.city.required">Phone Number is required</span>
                    <span class="md-error" v-else-if="!$v.form.details.city.maxLength">Too many characters</span>
                </md-field>
            </div>

            <!-- State -->

            <div class="field" :class="getValidationClass('state')">
                <!-- <md-field :class="getValidationClass('state')"> -->
                    <md-autocomplete v-model="form.details.state" :md-options="states" md-input-name="2304823" ref="inputState">
                        <label>State *</label>
                    </md-autocomplete>
                    <span class="md-error" v-if="!$v.form.details.state.required">State is required</span>
                <!-- </md-field> -->
            </div>

            <!-- Zip -->

            <div class="field" :class="getValidationClass('zip')">
                <md-field :class="getValidationClass('zip')">
                    <label for="zip">Zip Code *</label>
                    <md-input name="zip" id="zip" v-model="form.details.zip" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.details.zip.required">Phone Number is required</span>
                    <span class="md-error" v-else-if="!$v.form.details.zip.maxLength">Too many characters</span>
                </md-field>
            </div>

            <!-- Sports -->

            <div class="field" :class="getValidationClass('sports')">
                <md-field :class="getValidationClass('sports')">
                    <label for="sports">Sports *</label>
                    <md-select v-model="form.details.sports" name="sports" id="sports" multiple>
                        <md-option v-for="(sport, $index) of sports" :key="$index" :value="sport">{{ sport }}</md-option>
                    </md-select>
                    <span class="md-error" v-if="!$v.form.details.sports.minLength">Please choose at least one sport</span>
                    <span class="md-error" v-else-if="!$v.form.details.sports.maxLength">You may choose up to 10 sports</span>
                </md-field>
            </div>

            <!-- School Division -->

            <div class="field" :class="getValidationClass('school_division')">
                <md-field :class="getValidationClass('school_division')">
                    <label for="school_division">School Division *</label>
                    <md-select v-model="form.details.school_division" name="school_division" id="school_division">
                        <md-option v-for="division in divisions" :key="division.value" :value="division.value">{{ division.label }}</md-option>
                    </md-select>
                    <span class="md-error" v-if="!$v.form.details.school_division.required">Please choose the division of your school</span>
                </md-field>
            </div>

            <!-- School Name -->

            <div class="field" :class="getValidationClass('school_name')">
                <md-field :class="getValidationClass('school_name')">
                    <label for="school_name">School Name *</label>
                    <md-input name="school_name" id="school_name" v-model="form.details.school_name" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.details.school_name.required">School name is required</span>
                    <span class="md-error" v-else-if="!$v.form.details.school_name.maxLength">Too many characters</span>
                </md-field>
            </div>

            <!-- Graduation Date -->

            <div class="field" :class="getValidationClass('graduation_date')">
                <label head for="graduation_date">Graduation Date *</label>
                <md-datepicker v-model="form.details.graduation_date" :md-override-native="true" :md-model-type="String">
                    <label>Select date</label>
                </md-datepicker>
                <span class="md-error" v-if="!$v.form.details.graduation_date.required">Please choose a date</span>
            </div>

            <!-- Gender -->

            <div class="field" :class="getValidationClass('gender')">
                <md-field :class="getValidationClass('gender')">
                    <label for="gender">Gender *</label>
                    <md-select v-model="form.details.gender" name="gender" id="gender">
                        <md-option value="Male">Male</md-option>
                        <md-option value="Female">Female</md-option>
                        <md-option value="Other">Other</md-option>
                    </md-select>
                    <span class="md-error" v-if="!$v.form.details.school_division.required">Please select a gender</span>
                </md-field>
            </div>

            

            <!-- Instagram -->

            <div class="field" :class="getValidationClass('has_instagram')">
                <label head for="has_instagram">Are you on Instagram?</label>
                <md-radio v-model="form.details.has_instagram" :value="1">Yes</md-radio>
                <md-radio v-model="form.details.has_instagram" :value="0">No</md-radio>

                <div v-if="form.details.has_instagram === 1">

                    <!-- Profile -->

                    <md-field :class="getValidationClass('instagram_profile')">
                        <label for="instagram_profile">Instagram Username *</label>
                        <md-input name="instagram_profile" id="instagram_profile" v-model="form.details.instagram_profile" :disabled="sending" />
                        <span class="md-error" v-if="!$v.form.details.instagram_profile.required">Required</span>
                    </md-field>

                    <!-- Followers -->

                    <md-field :class="getValidationClass('instagram_followers')">
                        <label for="instagram_followers">How many followers? *</label>
                        <md-input name="instagram_followers" id="instagram_followers" v-model="form.details.instagram_followers" :disabled="sending" type="number" />
                        <span class="md-error" v-if="!$v.form.details.instagram_followers.required">Required</span>
                    </md-field>

                    <!-- Screenshot -->

                    <label head for="instagram_screenshot">Add "College Hype Athlete" to you Instagram Bio and upload a screenshot here.</label>
                    <md-field :class="getValidationClass('instagram_screenshot')">
                        <md-file v-model="form.details.instagram_screenshot" :clear="true" @md-change="fileSelected($event, 'instagram_screenshot')" />
                        <span class="md-error" v-if="!$v.form.details.instagram_screenshot.required">Please upload an image</span>
                    </md-field>
                </div>
            </div>

            <!-- Twitter -->

            <div class="field" :class="getValidationClass('has_twitter')">
                <label head for="has_twitter">Are you on Twitter?</label>
                <md-radio v-model="form.details.has_twitter" :value="1">Yes</md-radio>
                <md-radio v-model="form.details.has_twitter" :value="0">No</md-radio>

                <div v-if="form.details.has_twitter === 1">

                    <!-- Profile -->

                    <md-field :class="getValidationClass('twitter_profile')">
                        <label for="twitter_profile">Twitter Username *</label>
                        <md-input name="twitter_profile" id="twitter_profile" v-model="form.details.twitter_profile" :disabled="sending" />
                        <span class="md-error" v-if="!$v.form.details.twitter_profile.required">Required</span>
                    </md-field>

                    <!-- Followers -->

                    <md-field :class="getValidationClass('twitter_followers')">
                        <label for="twitter_followers">How many followers? *</label>
                        <md-input name="twitter_followers" id="twitter_followers" v-model="form.details.twitter_followers" :disabled="sending" type="number" />
                        <span class="md-error" v-if="!$v.form.details.twitter_followers.required">Required</span>
                    </md-field>

                    <!-- Screenshot -->

                    <label head for="twitter_screenshot">Add "College Hype Athlete" to you Twitter Bio and upload a screenshot here.</label>
                    <md-field :class="getValidationClass('twitter_screenshot')">
                        <md-file v-model="form.details.twitter_screenshot" :clear="true" @md-change="fileSelected($event, 'twitter_screenshot')" />
                        <span class="md-error" v-if="!$v.form.details.twitter_screenshot.required">Please upload an image</span>
                    </md-field>
                </div>
            </div>

            <!-- TikTok -->

            <div class="field" :class="getValidationClass('has_tiktok')">
                <label head for="has_tiktok">Are you on TikTok?</label>
                <md-radio v-model="form.details.has_tiktok" :value="1">Yes</md-radio>
                <md-radio v-model="form.details.has_tiktok" :value="0">No</md-radio>

                <div v-if="form.details.has_tiktok === 1">

                    <!-- Profile -->

                    <md-field :class="getValidationClass('tiktok_profile')">
                        <label for="tiktok_profile">TikTok Username *</label>
                        <md-input name="tiktok_profile" id="tiktok_profile" v-model="form.details.tiktok_profile" :disabled="sending" />
                        <span class="md-error" v-if="!$v.form.details.tiktok_profile.required">Required</span>
                    </md-field>

                    <!-- Followers -->

                    <md-field :class="getValidationClass('tiktok_followers')">
                        <label for="tiktok_followers">How many followers? *</label>
                        <md-input name="tiktok_followers" id="tiktok_followers" v-model="form.details.tiktok_followers" :disabled="sending" type="number" />
                        <span class="md-error" v-if="!$v.form.details.tiktok_followers.required">Required</span>
                    </md-field>

                    <!-- Screenshot -->

                    <label head for="tiktok_screenshot">Add "College Hype Athlete" to you TikTok Bio and upload a screenshot here.</label>
                    <md-field :class="getValidationClass('tiktok_screenshot')">
                        <md-file v-model="form.details.tiktok_screenshot" :clear="true" @md-change="fileSelected($event, 'tiktok_screenshot')" />
                        <span class="md-error" v-if="!$v.form.details.tiktok_screenshot.required">Please upload an image</span>
                    </md-field>
                </div>
            </div>

            <!-- Selfie -->

            <div class="field" :class="getValidationClass('selfie')">
                <label head for="selfie">Upload a picture of yourself in your school uniform *</label>
                <md-field :class="getValidationClass('selfie')">
                    <md-file v-model="form.details.selfie" :clear="true" @md-change="fileSelected($event, 'selfie')" />
                    <span class="md-error" v-if="!$v.form.details.selfie.required">Please upload an image</span>
                </md-field>
            </div>

            

            <!-- Submit -->

            <p w cen v-if="showSubmitBtn">
                <a btn class="large blue" @click="send">Submit</a>
            </p>

            <md-snackbar md-position="center" :md-duration="3000" :md-active.sync="showFormInvalid" md-persistent>
              <span>There are form errors. Please review. </span>
              <md-button class="md-primary" @click="showFormInvalid = false">OK</md-button>
            </md-snackbar>

            <md-snackbar md-position="center" :md-duration="3000" :md-active.sync="showErrorNetwork">
              <span>There was a server or network error. Please try again.</span>
              <md-button class="md-primary" @click="showErrorNetwork = false">OK</md-button>
            </md-snackbar>

            <md-snackbar md-position="center" :md-duration="Infinity" :md-active.sync="sending" md-persistent>
              <span>Sending your request... </span>
            </md-snackbar>

        </form>
    </div>
</template>


<script>

    import { validationMixin } from 'vuelidate';

    import {
        required,
        requiredIf,
        email,
        maxLength,
    } from 'vuelidate/lib/validators'

    const isTrue = (value) => !!value;

    export default {
        name: 'ChsAthleteForm',

        mixins: [validationMixin],

        data: () => ({
            form: {
                first_name: '',
                last_name: '',
                email: '',
                details: {
                    birth_date: '',
                    email_school: '',
                    phone: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    sports: [],
                    school_division: '',
                    school_name: '',
                    graduation_date: '',
                    gender: '',
                    is_agreement_accepted: '',
                    has_instagram: '',
                    instagram_profile: '',
                    instagram_followers: '',
                    instagram_screenshot: '',
                    has_twitter: '',
                    twitter_profile: '',
                    twitter_followers: '',
                    twitter_screenshot: '',
                    has_tiktok: '',
                    tiktok_profile: '',
                    tiktok_followers: '',
                    tiktok_screenshot: '',
                    is_gpa_acknowledged: '',
                    is_community_service_acknowledged: '',
                    is_core_values_acknowledged: '',
                    selfie: '',
                    essay: '',
                }
            },
            files: {},
            sending: false,
            showFormInvalid: false,
            showErrorNetwork: false,
            sports: [
                'Badminton',
                'Baseball (Boys)',
                'Basketball',
                'Bowling',
                'Cheerleading / Competitive Spirit Squads',
                'Cross Country',
                'Dance Team',
                'Field Hockey',
                'Flag Football (Girls)',
                'Football (Boys)',
                'Golf',
                'Gymnastics',
                'Hockey',
                'Indoor Track & Field',
                'Lacrosse',
                'Skiing & Snowboarding',
                'Soccer',
                'Softball (Girls)',
                'Slow Pitch Softball (Girls)',
                'Surf',
                'Swimming & Diving',
                'Tennis',
                'Track & Field',
                'Volleyball',
                'Water Polo',
                'Weightlifting',
                'Wrestling',
            ],
            states: ['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming'],
            divisions: [
                { label: 'Division 1', value: '1' },
                { label: 'Division 2', value: '2' },
                { label: 'Division 3', value: '3' },
            ],
        }),

        props: {
            showSubmitBtn: {
                type: Boolean,
                default: true,
            },
        },

        validations: {
            form: {
                first_name: {
                    required,
                    maxLength: maxLength(64),
                },
                last_name: {
                    required,
                    maxLength: maxLength(64),
                },
                email: {
                    required,
                    email,
                    maxLength: maxLength(64),
                },
                details: {
                    birth_date: { required },
                    email_school: { required, email },
                    phone: { required, maxLength: maxLength(64) },
                    address: { required, maxLength: maxLength(64) },
                    city: { required, maxLength: maxLength(64) },
                    state: { required, maxLength: maxLength(64) },
                    zip: { required, maxLength: maxLength(64) },
                    sports: { required, maxLength: maxLength(10) },
                    school_division: { required },
                    school_name: { required, maxLength: maxLength(64) },
                    graduation_date: { required },
                    gender: { required },
                    is_agreement_accepted: { required, isTrue },
                    has_instagram: { required },
                    instagram_profile: { required: requiredIf( (details) => { return !!details.has_instagram } ) },
                    instagram_followers: { required: requiredIf( (details) => { return !!details.has_instagram } ) },
                    instagram_screenshot: { required: requiredIf( (details) => { return !!details.has_instagram } ) },
                    has_twitter: { required },
                    twitter_profile: { required: requiredIf( (details) => { return !!details.has_twitter } ) },
                    twitter_followers: { required: requiredIf( (details) => { return !!details.has_twitter } ) },
                    twitter_screenshot: { required: requiredIf( (details) => { return !!details.has_twitter } ) },
                    has_tiktok: { required },
                    tiktok_profile: { required: requiredIf( (details) => { return !!details.has_tiktok } ) },
                    tiktok_followers: { required: requiredIf( (details) => { return !!details.has_tiktok } ) },
                    tiktok_screenshot: { required: requiredIf( (details) => { return !!details.has_tiktok } ) },
                    is_gpa_acknowledged: { required },
                    is_community_service_acknowledged: { required },
                    is_core_values_acknowledged: { required },
                    selfie: { required },
                    essay: { required, maxLength: maxLength(1000) },
                },
            }
        },

        methods: {

            fileSelected (ev, field) {

                for ( let i in ev ) {
                    if ( i !== 'item' && i !== 'length' ) {
                        this.files[field] = ev[i];
                    }
                }
                // this.files[field] = ev[0];
            },

            getValidationClass (fieldName) {
                const field = this.$v.form[fieldName] || this.$v.form.details[fieldName];
                // console.log('field to validate', fieldName, field);

                if ( field )
                {
                    return {
                        'md-invalid': field.$invalid && field.$dirty,
                        'error': field.$invalid && field.$dirty,
                    }
                }
            },

            send () 
            {
                if ( this.sending ) { return false }

                this.$v.$touch()
                this.sending = false;

                if ( this.$v.$invalid ) {
                    this.showFormInvalid = true;
                    return false;
                }

                this.sending = true;

                let FD = new FormData()

                for ( let f in this.form )
                {
                    if ( f === 'details' ) {
                        FD.append(f, JSON.stringify(this.form[f]));
                    } else {
                        FD.append(f, this.form[f]);
                    }
                }

                for ( let i in this.files )
                {
                    FD.append('files['+i+']', this.files[i])
                }

                //

                window.axios.post('api/chs-athlete/form/submit', FD, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then( (res) => {
                    this.sending = false;
                    window.location.href = 'chs-athlete/form/success';
                    console.log(res);
                })
                .catch( (res) => {
                    console.error(res);
                    this.sending = false;
                    this.showErrorNetwork = true;
                })
            }

        },

    };

</script>

<style lang="scss">

    @import './../styles/colors.scss';

    div.field {
        padding: 1em;
        margin-bottom: 1em;
        background: #fff;
        border-radius: 1em;
        border: 1px solid #fff;

        &.error {
            border: 1px solid red;
        }

        label[head] {
            display: block;
            /*border-bottom: 1px solid #ccc;*/
            border-radius: 1em;
            padding: .5em 1em;
            background: $colorLight;
            margin-bottom: 1em;
        }
    }

    .md-snackbar {
        span {
            color: #fff;
        }
    }

    .md-button.md-primary div {
        color: #fff;
    }

    .md-datepicker-dialog {
        /*position: fixed !important;*/
        /*top: unset;*/
        /*left: 0 !important;*/
        /*bottom: 0 !important;*/
        max-width: 600px;

        strong {
            color: #fff;
        }

        path {
            fill: unset;
        }


        .md-datepicker-body {
            min-width: 300px;
        }

        .md-datepicker-body-footer {
            .md-button-content {
                color: #555;
            }
        }

    }

    .md-textarea {
        width: 100%;
    }

</style>
