<template>
    <div class="catalog-filters">

        <h3>Catalog Filters</h3>

        <b>Search</b>

        <md-field search
        md-clearable>
            <div class="icon"><i class="pi pi-search"></i></div>
            <!-- <label>Type a Name, Tag, or Brand...</label> -->
            <md-input 
            v-model="filters.search"
            @input="applyFilters"></md-input>
            <span class="md-helper-text">Enter a Name, Tag, or Brand...</span>
        </md-field>

        <div v-if="showNonSearchFilters">
        
            <!-- <h4>Type</h4>

            <div>
                <md-radio 
                v-model="filters.section" 
                :value="section"
                v-for="section in sections" 
                :key="section.id"
                @change="applyFilters">{{ section.name }}</md-radio>
            </div> -->

            <div v-if="showCategoryFilter">

                <h4>Catalog Links</h4>

                <catalog-provider-links></catalog-provider-links>

                <h4>Category</h4>

                <div>
                    <md-radio 
                    v-model="filters.category" 
                    :value="category"
                    v-for="category in categories" 
                    :key="category.id"
                    @change="applyFilters">{{ category.name }}</md-radio>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    name: 'v-catalog-filters',
    props: {
        section: String,
        search: String,
    },
    data: function() {
        return {
            sections: [],
            categories: [],
            filters: {
                section: {},
                category: {},
            },
            showCategoryFilter: true,
            showNonSearchFilters: true,
        }
    },
    methods: {
        getSections () 
        {
            window.axios.get('/api/catalogs/sections')
            .catch(() => {

            })
            .then((res) => {
                this.sections = res.data
                this.sections.unshift({name: 'All'})

                // Define a default section based on URL, if one was provided
                // Route param gets passed in from parent
                // Create association between URI segment and Section ID
                // Find the Section from the array having the Section ID

                if ( !this.section ) { 
                    this.filters.section = {name: 'All'}
                    return true 
                }

                let sectionId = 0;

                switch ( this.section )
                {
                    case 'search':
                    case 'all':
                    sectionId = 0;
                    break;

                    case 'custom_apparel':
                    sectionId = 1;
                    break;

                    case 'promotional':
                    sectionId = 2;
                    break;

                    case 'retail_brands':
                    sectionId = 3;
                    break;

                    case 'accessories':
                    sectionId = 4;
                    break;

                    case 'schools':
                    sectionId = 7;
                    break;
                }

                let section = window._.find( this.sections, (o) => { return o.id === sectionId } );
                this.filters.section = section;
                
                this.applyFilters(false);
            })
        },
        getCategories () 
        {
            window.axios.get('/api/catalogs/categories')
            .catch(() => {

            })
            .then((res) => {
                this.categories = res.data
                this.categories.unshift({name: 'All'})
            })
        },
        applyFilters(resetSection)
        {
            // Reset chosen Section for cases when the URI defines the selected section. This way a new Category can be chosen via the radio buttons - using Section: 'All'.

            if ( resetSection !== false )
            {
                let section = window._.find( this.sections, (o) => { return o.id === 0 } );
                this.filters.section = section;
            }

            this.showNonSearchFilters = !this.filters.search;

            // Some Sections do not use categories, clear it
            // Except now there is no more Schools section
            // and Retail Brands now contains "schools" category

            /*switch ( this.filters.section.name )
            {
                case 'Retail Brands':
                case 'Schools':
                    this.filters.category = null
                    this.showCategoryFilter = false;
                    break;
                default: 
                    this.showCategoryFilter = true; 
            }*/

            // Emit

            this.$emit('filter', this.filters)
        },
    },
    created() {
        this.filters.search = this.search;
        this.getSections()
        this.getCategories()
    },
}
</script>

<style lang="scss">

    .catalog-filters {
        background: #fff;
        padding: 1em;
            
        .md-button-content {
            svg path:last-child {
                display: none !important;
            }
        }

        h3 {
            text-transform: uppercase;
            font-weight: 400;
            font-weight: 900;
            padding: .5em;
            text-align: center;
            background: #eee;
        }

        h4 {
            margin: 1.5em 0;
            padding: .75em;
            /*border-bottom: 1px solid #ddd;*/
            background: #eee;
        }

        .md-field[search] {
            background: #eee;
            padding: .5em;
            border-radius: .3em;
            
            .text {
                background: #eee;
            }

            svg {
                color: #999;
                width: 30px;
            }

            > div:first-child {
                width: 40px;
            }
        }
    }


    

</style>

