<template>
    <div id="request-quote-form">

        <p>Please fill out this form as best as you can so we can get you an accurate quote!</p>
        <p>Not ready yet? Shop our <a href="https://collegehype.com/catalogs">Custom Catalog</a> or Browse our <a href="https://mycitygear.com/" target="_blank">Retail Stores</a>.</p>

        <p>For immediate assistance please call 617-282-8883 or <a href="contact-us/form">email us</a></p>

        <form @submit.prevent="validateUser" enctype="multipart/form-data" ref="form">

            <!-- Name -->

            <div class="field">
                <md-field :class="getValidationClass('name')">
                    <label for="name">Name (Required)</label>
                    <md-input name="first-name" id="name" autocomplete="given-name" v-model="form.name" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.name.required">Name is required</span>
                    <span class="md-error" v-else-if="!$v.form.name.maxLength">Max length: 64 chars</span>
                </md-field>
            </div>

            <!-- Email -->

            <div class="field">
                <md-field :class="getValidationClass('email')">
                    <label for="email">Email Address (Required)</label>
                    <md-input name="email" id="email" v-model="form.email" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.email.required">Email Address is required</span>
                    <span class="md-error" v-if="!$v.form.email.email">Must be a valid email address</span>
                    <span class="md-error" v-else-if="!$v.form.email.maxLength">Max length: 64 chars</span>
                </md-field>
            </div>

            <!-- Phone -->

            <div class="field">
                <md-field :class="getValidationClass('phone')">
                    <label for="phone">Phone Number (Required)</label>
                    <md-input name="phone" id="phone" v-model="form.phone" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.phone.required">Phone Number is required</span>
                    <span class="md-error" v-else-if="!$v.form.phone.maxLength">Too many characters</span>
                </md-field>
            </div>

            <!-- Contact Method -->

            <div class="field" :class="{error: !$v.form.contact_method.required}">
                <label head for="contact_method">Preferred Contact Method</label>
                <md-radio v-model="form.contact_method" value="email">Email</md-radio>
                <md-radio v-model="form.contact_method" value="phone">Phone</md-radio>
                <md-radio v-model="form.contact_method" value="other">Other</md-radio>

                <md-field :class="getValidationClass('contact_method')" v-if="form.contact_method === 'other'">
                    <label for="other">Other Contact Method</label>
                    <md-input name="contact_method_other" id="contact_method_other" v-model="form.contact_method_other" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.contact_method_other.maxLength">Too many characters</span>
                </md-field>
            </div>

            <!-- Services -->

            <div class="field" :class="{error: form.services.length < 1}">
                <label head for="services">What Services Do You Need?</label>
                <md-checkbox v-model="form.services" value="Screen Printing">Screen Printing</md-checkbox>
                <md-checkbox v-model="form.services" value="Embroidery">Embroidery</md-checkbox>
                <md-checkbox v-model="form.services" value="Promotional">Promotional</md-checkbox>
                <md-checkbox v-model="form.services" value="Other">Other</md-checkbox>

                <md-field :class="getValidationClass('services_other')">
                    <label for="services_other">Other Service(s)</label>
                    <md-input name="services_other" id="services_other" v-model="form.services_other" :disabled="sending" />
                </md-field>
            </div>

            <!-- Garment Type -->

            <!-- <div class="field" :class="{error: !$v.form.garment_type.required}">
                <label head for="garment_type">What Type of Garment Do You Need?</label>
                <md-radio v-model="form.garment_type" value="T-Shirt">T-Shirt</md-radio>
                <md-radio v-model="form.garment_type" value="Long Sleeve Tee">Long Sleeve Tee</md-radio>
                <md-radio v-model="form.garment_type" value="Hooded Sweatshirt">Hooded Sweatshirt</md-radio>
                <md-radio v-model="form.garment_type" value="Jacket">Jacket</md-radio>
                <md-radio v-model="form.garment_type" value="Vest">Vest</md-radio>
                <md-radio v-model="form.garment_type" value="other">Other</md-radio>

                <md-field :class="getValidationClass('garment_type_other')" v-if="form.garment_type === 'other'">
                    <label for="other">Please specify</label>
                    <md-input name="garment_type_other" id="garment_type_other" v-model="form.garment_type_other" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.garment_type_other.maxLength">Too many characters</span>
                </md-field>
            </div> -->

            <!-- Garment Color -->

            <!-- <div class="field" :class="{error: !$v.form.garment_color.required}">
                <label head for="garment_color">Garment Color</label>
                <md-radio v-model="form.garment_color" value="Dark">Dark</md-radio>
                <md-radio v-model="form.garment_color" value="Light">Light</md-radio>
            </div> -->

            <!-- Quantity -->

            <div class="field">
                <md-field :class="getValidationClass('quantity')">
                    <label for="quantity">Quantity Needed (Minimum: 24 units)</label>
                    <md-input name="quantity" id="quantity" v-model="form.quantity" :disabled="sending" type="number" />
                    <span class="md-error" v-if="!$v.form.quantity.required">Please specify a quantity</span>
                    <span class="md-error" v-else-if="!$v.form.quantity.min">Minimum quantity: 24 units</span>
                </md-field>
            </div>

            <!-- Thread Colors: Front -->

            <!-- <div class="field" :class="{error: !$v.form.num_colors_front.required}">
                <label head for="num_colors_front">How Many Ink/Thread Colors? (Front Print)</label>
                <md-radio v-model="form.num_colors_front" value="1">1</md-radio>
                <md-radio v-model="form.num_colors_front" value="2">2</md-radio>
                <md-radio v-model="form.num_colors_front" value="3">3</md-radio>
                <md-radio v-model="form.num_colors_front" value="4">4</md-radio>
                <md-radio v-model="form.num_colors_front" value="5">5</md-radio>
                <md-radio v-model="form.num_colors_front" value="Other">Other</md-radio>
                <md-radio v-model="form.num_colors_front" value="No Front Print">No Front Print</md-radio>
            </div> -->

            <!-- Thread Colors: Back -->

            <!-- <div class="field" :class="{error: !$v.form.num_colors_back.required}">
                <label head for="num_colors_back">How Many Ink/Thread Colors? (Back Print)</label>
                <md-radio v-model="form.num_colors_back" value="1">1</md-radio>
                <md-radio v-model="form.num_colors_back" value="2">2</md-radio>
                <md-radio v-model="form.num_colors_back" value="3">3</md-radio>
                <md-radio v-model="form.num_colors_back" value="4">4</md-radio>
                <md-radio v-model="form.num_colors_back" value="5">5</md-radio>
                <md-radio v-model="form.num_colors_back" value="Other">Other</md-radio>
                <md-radio v-model="form.num_colors_back" value="No Back Print">No Back Print</md-radio>
            </div> -->

            <!-- Thread Colors: Sleeve -->

            <!-- <div class="field" :class="{error: !$v.form.num_colors_sleeve.required}">
                <label head for="num_colors_sleeve">How Many Ink/Thread Colors? (Sleeve Print)</label>
                <md-radio v-model="form.num_colors_sleeve" value="1">1</md-radio>
                <md-radio v-model="form.num_colors_sleeve" value="2">2</md-radio>
                <md-radio v-model="form.num_colors_sleeve" value="3">3</md-radio>
                <md-radio v-model="form.num_colors_sleeve" value="4">4</md-radio>
                <md-radio v-model="form.num_colors_sleeve" value="Other">Other</md-radio>
                <md-radio v-model="form.num_colors_sleeve" value="No Sleeve Print">No Sleeve Print</md-radio>
            </div> -->

            <!-- Date Needed -->

            <div class="field" :class="{error: !$v.form.date_needed.required}">
                <label head for="date_needed">When Do You Need By?</label>
                <md-datepicker v-model="form.date_needed" :md-override-native="true" :md-model-type="String">
                    <label>Select date</label>
                </md-datepicker>
                <span class="md-error" v-if="!$v.form.date_needed.required">Please choose a date</span>
            </div>

            <!-- Artwork -->

            <div class="field">
                <label head for="date_needed">Artwork (If you have it)</label>
                <md-field>
                    <md-file v-model="form.artwork" multiple :clear="true" @md-change="fileSelected($event)" />
                </md-field>
            </div>

            <!-- How Hear -->

            <div class="field">
                <label head for="hear">How did you hear about us?</label>
                <md-radio v-model="form.hear" :value="how" v-for="(how, index) in hows" :key="index">{{ how }}</md-radio>

                <!-- Specify -->

                <md-field :class="getValidationClass('hear_specify')" v-if="form.hear === 'Sales Rep' || form.hear === 'Other'">
                    <label for="other">Please specify</label>
                    <md-input name="hear_specify" id="hear_specify" v-model="form.hear_specify" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.hear_specify.maxLength">Too many characters</span>
                </md-field>

            </div>

            <!-- Additional Details -->
            
            <div class="field">
                <label head for="date_needed">Additional Details</label>
                <p>Please provide us with any additional specifics to your order. If you need pricing on something that isn't included in this form please call 617-282-8883 or email sales@collegehype.net with your inquiry</p>
                <md-field>
                    <md-textarea v-model="form.additional_details" style="width:100%" rows="10"></md-textarea>
                    <span class="md-error" v-if="!$v.form.additional_details.maxLength">Maximum of 500 characters</span>
                </md-field>
            </div>

            <!-- <md-button class="md-raised md-primary" @click="send">Submit</md-button> -->
            <p w cen v-if="showSubmitBtn">
                <a btn class="large blue" @click="send">Submit</a>
            </p>

            <md-snackbar md-position="center" :md-duration="4000" :md-active.sync="showFormInvalid" :md-persistent="true" class="md-danger" style="background: #f33; height: 150px;">
              <div style="text-align: center; font-size: 1.2em;"><span>Some form fields are incomplete.</span></div>
              <!-- <md-button class="md-primary" @click="showFormInvalid = false">OK</md-button> -->
            </md-snackbar>

            <md-snackbar md-position="center" :md-duration="Infinity" :md-active.sync="sending" md-persistent style="background: #33f; height: 150px;">
                <div style="text-align: center; font-size: 1.2em;"><span>Sending Request...</span></div>
            </md-snackbar>

        </form>
    </div>
</template>


<script>

    import { validationMixin } from 'vuelidate';

    import {
        required,
        email,
        // minLength,
        maxLength,
        minValue,
    } from 'vuelidate/lib/validators'

    export default {
        name: 'RequestQuoteForm',

        mixins: [validationMixin],

        data: () => ({
            form: {
                name: '',
                email: '',
                phone: '',
                contact_method: '',
                contact_method_other: '',
                services: [],
                services_other: '',
                garment_type: '',
                garment_type_other: '',
                garment_color: '',
                quantity: '',
                num_colors_front: '',
                num_colors_back: '',
                num_colors_sleeve: '',
                date_needed: null,
                artwork: '',
                hear: '',
                hear_specify: '',
            },
            files: [],
            sending: false,
            showFormInvalid: false,
            hows: [
                'Search Engine',
                'Recommended by a colleague or friend',
                'Social media',
                'Blog or publication',
                'Sales Rep',
                'Other',
            ],
        }),

        props: {
            showSubmitBtn: {
                type: Boolean,
                default: true,
            },
        },

        validations: {
            form: {
                name: {
                    required,
                    maxLength: maxLength(64),
                },
                email: {
                    required,
                    email,
                    maxLength: maxLength(64),
                },
                phone: {
                    required,
                    maxLength: maxLength(64),
                },
                contact_method: {
                    required,
                },
                contact_method_other: {
                    maxLength: maxLength(128),
                },
                services: {
                    maxLength: maxLength(128),
                },
                // garment_type: {
                //     required,
                // },
                garment_type_other: {
                    maxLength: maxLength(128),
                },
                // garment_color: {
                //     required,
                // },
                quantity: {
                    required,
                    min: minValue(24),
                },
                // num_colors_front: {
                //     required,
                // },
                // num_colors_back: {
                //     required,
                // },
                // num_colors_sleeve: {
                //     required,
                // },
                hear: {

                },
                hear_specify: {
                    
                },
                date_needed: {
                    required,
                },
                artwork: {
                    // required,
                },
                additional_details: {
                    maxLength: maxLength(500),
                }
            }
        },

        methods: {

            fileSelected (ev) {

                this.files = [];

                for ( let i in ev ) {
                    if ( i !== 'item' && i !== 'length' ) {
                        this.files[i] = ev[i];
                    }
                }
            },

            getValidationClass (fieldName) {
                const field = this.$v.form[fieldName];

                if ( field )
                {
                    return {
                        'md-invalid': field.$invalid && field.$dirty,
                    }
                }
            },

            scrollToEl (queryString, queryStringAlt) {
                const $el = document.querySelectorAll(queryString);
                const $els = [...$el, ...document.querySelectorAll(queryStringAlt)];
                const y = $els[0]?.offsetTop || 0;
                window.scroll(0, y - 100);
            },

            send () 
            {
                if ( this.sending ) { return false }

                this.$v.$touch()
                this.sending = false;

                if ( this.$v.$invalid ) {
                    this.showFormInvalid = true;
                    this.$nextTick(() => {
                        this.scrollToEl('.md-invalid', '.error');
                    });
                    return false;
                }

                this.sending = true;

                let FD = new FormData()

                for ( let f in this.form )
                {
                    FD.append(f, this.form[f]);
                }

                for ( let i in this.files )
                {
                    FD.append('file['+i+']', this.files[i])
                }

                window.axios.post('api/request-a-quote/form/submit', FD, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then( () => {
                    // this.sending = false;
                    window.location.href = 'request-a-quote/form/success';
                    // console.log(res);
                })
                .catch()
            }

        },
    };

</script>

<style lang="scss">

    @import './../styles/colors.scss';

    div.field {
        padding: 1em;
        margin-bottom: 1em;
        background: #fff;
        border-radius: 1em;
        border: 1px solid #fff;

        &.error {
            border: 1px solid red;
        }

        label[head] {
            display: block;
            /*border-bottom: 1px solid #ccc;*/
            border-radius: 1em;
            padding: .5em 1em;
            background: $colorLight;
            margin-bottom: 1em;
        }
    }

    .md-snackbar {
        span {
            color: #fff;
        }
    }

    .md-button.md-primary div {
        color: #fff;
    }

    .md-datepicker-dialog {
        /*position: fixed !important;*/
        /*top: unset;*/
        /*left: 0 !important;*/
        /*bottom: 0 !important;*/
        max-width: 600px;

        strong {
            color: #fff;
        }

        path {
            fill: unset;
        }


        .md-datepicker-body {
            min-width: 300px;
        }

        .md-datepicker-body-footer {
            .md-button-content {
                color: #555;
            }
        }

    }

</style>
