<template>

  <div id="gallery-slide-show">
    
    <md-dialog :md-active.sync="showDialog" :class="'gallery-dialog'">

      <md-dialog-title vcen grid><span>{{ title }}</span> <md-button class="md-primary" @click="show = false">Close</md-button></md-dialog-title>

      <div id="gallery-slideshow" class="swiper-container" ref="gallerySlideshow">
        <div class="items swiper-wrapper">
          <div 
          v-for="(slide, index) in slides"
          class="item swiper-slide" 
          :style="bg(index)" 
          :key="index">
            <span class="title">
              {{ slide.title }}<br />
              <sub v-html="slide.caption"></sub>
            </span>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>


    </md-dialog>

  </div>
  
  

</template>

<script>

  import Swiper, {Navigation} from 'swiper';

  Swiper.use([Navigation]);


  export default {
    name: 'GallerySlideShow',

    data: () => ({
      showDialog: false,
    }),

    props: {
      slides: Array,
      title: String,
      show: Boolean,
    },

    watch: {
      show (val) {
        this.showDialog = val;
      },
      showDialog (val) {

        if ( val )
        {
          setTimeout( () => {
            new Swiper(this.$refs.gallerySlideshow, {
              navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev'
              },
              slidesPerView: 1,
              spaceBetween: 30,
              // preventClicks: false,
              // preventClicksPropagation: false,
            })
          }, 100)
          
        } else {
          this.$emit('hide');
        }

      }
    },

    methods: {

      bg (index) {
        return {
          backgroundImage: 'url(' + this.slides[index].photoComputed + ')',
        }
      },

    },

    mounted () {

      

    },

  }
</script>


<style lang="scss">

    @import './../styles/colors.scss';

   .md-dialog.gallery-dialog .md-dialog-container {
    width: 100vw;
    height: 100vh;
    max-width: unset;
    max-height: unset;

    .md-dialog-title {
      padding: .5em 1em;
      margin: 0;
      background: $colorSecondary;
      color: #fff;
    }

    .md-button {
      float: right;

      .md-ripple {
        color: #fff;

        .md-button-content {
          color: inherit;
        }
      }
    }

    
  }

  #gallery-slideshow {
    height: 100%;
  }

  #gallery-slideshow.swiper-container {

    width: 100%;
    background: #111;
    /*padding: 1em 3em;*/
    vertical-align: top;
    /*margin-bottom: 2em;*/



    .items {
        width: 100%;
        margin-bottom: 1em;

        .item {
            /*width: ;*/
            /*transition: background .3s;*/
            padding: 0 .5em;
            /*min-width: 375px;*/
            /*max-width: 400px;*/
            color: #555;
            height: 100%;

            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;

            span {
                position: absolute;
                left: 0; bottom: 0;
                padding: 1em;
                background: #000a;
                color: #fff;
                text-align: center;

                sub {
                    color: inherit;
                }
            }

            /*.img {
                width: 100%;
                height: 600px;
                position: relative;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                margin-bottom: .5em;



                div {
                    display: none;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: none;
                    transition: opacity .3s;
                    text-align: center;
                    opacity: 0;

                    b {
                        padding: .5em 1em;
                        background: $colorPrimary;
                        color: #fff;
                        text-transform: uppercase;
                        border: 1px solid #fff;
                        border-radius: 10em;
                        transition: background .3s;
                        font-weight: normal;

                        &:hover {
                            background: $colorTertiary;
                        }
                    }
                }
            }*/

            .title {
                width: 100%;
                padding: 1em 0;
                font-size: 1.3em;
                text-align: center;
            }

        }
    }


    .swiper-button-next,
    .swiper-button-prev {
        height: 100%;
        top: 0;
        /*background: #fff9;*/
        padding: 2em;
    }

    .swiper-button-next {
        right: 0;
    }

    .swiper-button-prev {
        left: 0;
    }

} /* swiper container */

[grid] {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

</style>
