<template>
  
  <div id="gallery-launcher" @click="show = true">
    
    <div id="g-preview-slides">
      <div :style="bg(0)"></div>
      <div :style="bg(1)"></div>
      <div :style="bg(2)"></div>
      <div :style="bg(3)"></div>
    </div>

    <div id="g-cta" vcen>
      <span>{{ label }}</span>
    </div>

    <GallerySlideShow :slides="slides" :title="label" :show.sync="show" @hide="show = false" />

  </div>

</template>

<script>

  import GallerySlideShow from './GallerySlideShow.vue';

  export default {
    name: 'GalleryLauncher',

    components: {
      GallerySlideShow,
    },

    data: () => ({
      show: false,
    }),

    props: {
      slides: Array,
      label: String,
    },

    methods: {

      bg (index) {
        return {
          backgroundImage: 'url(' + this.slides[index].thumbComputed + ')',
        }
      }

    }

  }
</script>


<style lang="scss">

  #gallery-launcher {
    width: 100%;
    position: relative;
    background: #fff;
    cursor: pointer;
    border: 1em solid #fff;
    margin: 2em 0;

    #g-preview-slides {
      width: 100%;
      height: 18em;

      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1em;

      div {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        &:last-child {
          display: none;
        }
      }
    }

    #g-cta {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      text-align: center;
      padding: 0 2em;

      span {
        display: inline-block;
        width: 100%;
        max-width: 70%;
        text-align: center;
        padding: 1.2em;
        background: #000d;
        color: #fff;
        font-size: 1.5em;
        line-height: 1.2em;
        text-transform: uppercase;
        /*font-weight: bold;*/
        border-radius: 1em;
        letter-spacing: .01em;

        transition: padding .3s;
      }
    }

    &:hover {
      #g-cta span {
        padding: 1em 1.2em;
      }
    }

    @media (max-width: 800px ) {
      #g-preview-slides {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        div:last-child {
          display: block;
        }
      }

      #g-cta span {
        font-size: 1.2em;
        max-width: 100%;
      }
    }
  }

</style>
