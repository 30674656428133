<template>
    <div id="contact-form">

        <form @submit.prevent="validateUser" enctype="multipart/form-data" ref="form">

            <!-- Name -->

            <div class="field">
                <md-field :class="getValidationClass('name')">
                    <label for="name">Name (Required)</label>
                    <md-input name="first-name" id="name" autocomplete="given-name" v-model="form.name" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.name.required">Name is required</span>
                    <span class="md-error" v-else-if="!$v.form.name.maxLength">Max length: 64 chars</span>
                </md-field>
            </div>

            <!-- Email -->

            <div class="field">
                <md-field :class="getValidationClass('email')">
                    <label for="email">Email Address (Required)</label>
                    <md-input name="email" id="email" v-model="form.email" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.email.required">Email Address is required</span>
                    <span class="md-error" v-if="!$v.form.email.email">Must be a valid email address</span>
                    <span class="md-error" v-else-if="!$v.form.email.maxLength">Max length: 64 chars</span>
                </md-field>
            </div>

            <!-- Phone -->

            <div class="field">
                <md-field :class="getValidationClass('phone')">
                    <label for="phone">Phone Number (Required)</label>
                    <md-input name="phone" id="phone" v-model="form.phone" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.phone.required">Phone Number is required</span>
                    <span class="md-error" v-else-if="!$v.form.phone.maxLength">Too many characters</span>
                </md-field>
            </div>

            <!-- Department -->

            <div class="field" :class="{error: !$v.form.department.required}">
                <label head for="contact_method">Choose a department</label>
                <md-radio v-model="form.department" :value="dep.id" v-for="dep in departments" :key="dep.id">{{ dep.label }}</md-radio>
            </div>

            <!-- Additional Details -->

            <div class="field" :class="{error: !$v.form.message.required || !$v.form.message.maxLength}">
                <label head for="message">Your Message</label>
                <md-textarea v-model="form.message" style="width:100%" rows="10"></md-textarea>
                <span class="md-error" v-if="!$v.form.message.maxLength">Maximum of 500 characters</span>
            </div>

            <!-- How Hear -->

            <div class="field" :class="{error: !$v.form.hear.required}">
                <label head for="hear">How did you hear about us?</label>
                <md-radio v-model="form.hear" :value="how" v-for="(how, index) in hows" :key="index">{{ how }}</md-radio>
            </div>

            <p w cen>
                <a btn class="large blue" @click="send">Submit</a>
            </p>

            <md-snackbar md-position="center" :md-duration="4000" :md-active.sync="showFormInvalid" :md-persistent="true" class="md-danger" style="background: #f33; height: 150px;">
              <div style="text-align: center; font-size: 1.2em;"><span>Some form fields are incomplete.</span></div>
              <!-- <md-button class="md-primary" @click="showFormInvalid = false">OK</md-button> -->
            </md-snackbar>

            <md-snackbar md-position="center" :md-duration="Infinity" :md-active.sync="sending" md-persistent style="background: #33f; height: 150px;">
                <div style="text-align: center; font-size: 1.2em;"><span>Sending your information...</span></div>
            </md-snackbar>

        </form>
    </div>
</template>


<script>

    import { validationMixin } from 'vuelidate';

    import {
        required,
        email,
        // minLength,
        maxLength,
    } from 'vuelidate/lib/validators'

    export default {
        name: 'ContactUsForm',

        mixins: [validationMixin],

        data: () => ({
            form: {
                name: '',
                email: '',
                phone: '',
                department: '',
                message: '',
                hear: '',
            },
            sending: false,
            showFormInvalid: false,
            departments: [
                {
                    label: 'Sales',
                    id: 1,
                    name: 'sales',
                },
                {
                    label: 'Retail',
                    id: 2,
                    name: 'retail',
                },
                {
                    label: 'Customer Service',
                    id: 3,
                    name: 'customer-service',
                },
                {
                    label: 'Art Department',
                    id: 4,
                    name: 'art-department',
                },
            ],
            hows: [
                'Search Engine',
                'Recommended by a colleague or friend',
                'Social media',
                'Blog or publication',
                'Other',
            ],
        }),

        props: {
            department: null,
        },

        validations: {
            form: {
                name: {
                    required,
                    maxLength: maxLength(64),
                },
                email: {
                    required,
                    email,
                    maxLength: maxLength(64),
                },
                phone: {
                    required,
                    maxLength: maxLength(64),
                },
                department: {
                    required,
                    maxLength: maxLength(128),
                },
                message: {
                    required,
                    maxLength: maxLength(500),
                },
                hear: {
                    required,
                }
            }
        },

        mounted() {

            // Set the default department passed in as a prop, if any

            if ( this.department ) {
                for ( let d of this.departments ) {
                    if ( this.department === d.name ) {
                        this.form.department = d.id;
                    }
                }
            }
        },

        methods: {


            getValidationClass (fieldName) {
                const field = this.$v.form[fieldName];

                if ( field )
                {
                    return {
                        'md-invalid': field.$invalid && field.$dirty,
                    }
                }
            },
            
            scrollToEl (queryString, queryStringAlt) {
                const $el = document.querySelectorAll(queryString);
                const $els = [...$el, ...document.querySelectorAll(queryStringAlt)];
                const y = $els[0]?.offsetTop || 0;
                window.scroll(0, y - 100);
            },

            send () 
            {
                if ( this.sending ) { return false }
                    
                this.$v.$touch()
                this.sending = false;

                if ( this.$v.$invalid ) {
                    this.showFormInvalid = true;
                    this.$nextTick(() => {
                        this.scrollToEl('.md-invalid', '.error');
                    });
                    return false;
                }

                this.sending = true;

                let FD = new FormData()

                for ( let f in this.form )
                {
                    FD.append(f, this.form[f]);
                }

                window.axios.post('api/contact-us/form/submit', FD, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then( () => {
                    // this.sending = false;
                    window.location.href = 'contact-us/form/success';
                    // console.log(res);
                })
                .catch()
            }

        },
    };

</script>

<style lang="scss">

    @import './../styles/colors.scss';

    div.field {
        padding: 1em;
        margin-bottom: 1em;
        background: #fff;
        border-radius: 1em;
        border: 1px solid #fff;

        &.error {
            border: 1px solid red;
        }

        label[head] {
            display: block;
            /*border-bottom: 1px solid #ccc;*/
            border-radius: 1em;
            padding: .5em 1em;
            background: $colorLight;
            margin-bottom: 1em;
        }
    }

    .md-snackbar {
        span {
            color: #fff;
        }
    }

    .md-button.md-primary div {
        color: #fff;
    }


</style>
